var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ValidationObserver",
    { ref: "observer" },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }